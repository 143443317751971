import axios from 'axios';
import config from './../../config.js';

export const request = async () => {
    try {
        const response = await axios.get(`${config.baseUrl}wp-json/wp/v2/posts/210?_fields=acf&acf_format=standard`);
        return response.data.acf;
    } catch (error) {
        console.error('Erro ao buscar dados:', error);
        throw error; // Propagar o erro para o componente que chamou a função request
    }
};
