import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import './../dist/Menu.module.min.css';
import { request } from "../ApiMenu.js";
import settings from '../settingsSlider.js';

function SalgadosEncomenda() {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const menuData = await request(); // Chamando a função request para obter os dados
                setSections(menuData);
            } catch (error) {
                console.error('Erro ao buscar dados do menu:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Slider {...settings}>
            {sections && sections.map((section, index) => (
                // Verifica se a seção e suas informações de produto estão definidas
                section && (
                    // Verifica se a categoria é 'salgadosPanificadora' antes de renderizar o item
                    section.categoria === 'salgadosPanificadora' && (
                        <div className="card-slider" key={index}>
                            <div className="card-wrapper">
                                <div className="img-item" style={{backgroundImage: `url(${section.imagem_produto})`}}></div>
                                <div className="card-text-wrapper">
                                    <h2 className="font-title">{section.nome_produto}</h2>
                                    <p>{section.descricao} <br /> <b>{section.preco}</b></p>
                                </div>
                            </div>
                        </div>
                    )
                )
            ))}
        </Slider>
    )
}

export default SalgadosEncomenda;
