import axios from 'axios';
import config from './../../config';


export const request = async () => {
    try {
        const response = await axios.get(`${config.baseUrl}wp-json/wp/v2/posts/105?_fields=acf&acf_format=standard`);
        return response.data.acf.rodape.coluna;
    } catch (error) {
        console.error('Erro ao buscar dados do campo personalizado:', error);
        return [];
    }
};