import './dist/App.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import Home from './pages/home/Home.js';
import Menu from './pages/menu/Menu.js';
import Contact from './pages/contact/Contact.js';
import Gallery from './pages/gallery/Gallery.js';

import NavHeader from './components/header/NavHeader.js';
import Header from './components/header/Header.js';
import Footer from './components/footer/Footer.js';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const closeMenu = () => {
      setIsMenuOpen(false);
    };

    document.addEventListener('click', closeMenu);

    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [setIsMenuOpen]);

  return (
    <div className="App">
      <Router>
        <div className="flex-row">
          <NavHeader />
          <Routes>
            <Route exact path="/" element={
              <div className='wrapper'>
                <Home />
              </div>
            } />
            <Route exact path="/galeria/" element={
              <div className="wrapper">
                <Header title="Veja a nossa galeria" />
                <Gallery />
              </div>
            } />
            <Route exact path="/cardapio/" element={
              <div className="wrapper">
                <Header title="Aprecie o verdadeiro sabor" />
                <Menu />
              </div>
            } />
            <Route exact path="/contato/" element={
              <div className="wrapper">
                <Header title="Sinta-se livre para nos contatar" />
                <Contact />
              </div>
            } />
          </Routes>
        </div> {/* flex-row */}
        <Footer />
      </Router>
    </div>
  );
}

export function ImageWithHoverZoom({ imageUrl, className }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="image-resize" style={{ width: '100%', height: '100%' }}>
      <img 
        className={`${className} image-container`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        src={imageUrl}
        style={{
          transform: isHovered ? 'scale(1.2)' : 'scale(1)',
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Garante que a imagem preencha o contêiner mantendo a proporção
        }}
        alt='Imagem da Panificadora'
      />
    </div>
  );
}



export default App;
