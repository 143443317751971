import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import Container from '../container/Container.js';
import './dist/NavHeader.module.min.css';
import '../../dist/App.min.css';
import FontFamily from '../../font/FontFamily.js';
import { request } from './ApiNavHeader.js';

function NavHeader() {
    const [currentPage, setCurrentPage] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [headerData, setHeaderData] = useState({ logo: '', links: [], botao: '', endereco: '' }); // Initialize with a default structure
    const location = useLocation();
    const menuRef = useRef();

    useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await request(); // Call request function to get data
                if (responseData) {
                    setHeaderData(responseData); // Set header data
                } else {
                    console.error("Header data not found.");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <>
            <FontFamily />
            <div className={`${isMenuOpen ? 'show overlay' : ''}`} onClick={closeMenu}></div> {/* Add overlay for dark screen */}
            <nav className="nav-desktop">
                <Container>
                    <div className="container text-center ">
                        <ul>
                            {headerData.links.length > 0 && (
                                <>
                                    <li><Link to="/"><img className="logo" alt="Logomarca" src={headerData.logo} /></Link></li>
                                    {headerData.links.map((link, index) => (
                                        <li key={index}><Link to={link.url} style={{ color: currentPage === link.url ? 'white' : '#ced4e4' }}>{link.pagina_marcacao}</Link></li>
                                    ))}
                                </>
                            )}
                        </ul>
                        <hr />
                        <hr />
                        {headerData && (
                            <>
                                <a target='_blank' href="https://www.google.com/maps/place/Panificadora+Real/@-24.2437641,-50.239989,15z/data=!4m6!3m5!1s0x94e9ef8c3306ca43:0x3ab5431d9c3913eb!8m2!3d-24.2437641!4d-50.239989!16s%2Fg%2F11lkzzjnm1?entry=ttu" className="no-textEff button">{headerData.botao} <i className="fa-regular fa-chevron-right"></i></a>
                                <hr />
                                <p>{headerData.endereco}</p>
                            </>
                        )}
                    </div>
                </Container>
            </nav>

            <nav className="nav-mobile">
                <div className="container flex-row" ref={menuRef}>
                    <Link to="/"><img className="logo" alt="Logomarca" src={headerData.logo} /></Link>
                    <ul className={isMenuOpen ? 'open' : ''}>
                        <li><i className="fa-regular fa-x" onClick={closeMenu}></i></li>
                        {headerData.links.length > 0 && (
                            <>
                                {headerData.links.map((link, index) => (
                                    <li key={index}><Link to={link.url} style={{ color: currentPage === link.url ? 'white' : '#ced4e4' }}>{link.pagina_marcacao}</Link></li>
                                ))}
                            </>
                        )}
                    </ul>
                    <i className="fa-light fa-bars" onClick={toggleMenu}></i>
                </div>
            </nav>
        </>
    );
}

export default NavHeader;
