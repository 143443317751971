const settings = {
    appendDots: dots => (
        <div
            style={{
                position: "initial"
            }}
        >
            <ul style={{ paddingTop: "0", justifyContent: "center", gap: "0 2%" }}> {dots} </ul>
        </div>
        ),
        customPaging: i => (
        <div
            style={{
            
            color: "#777",
            padding: "2rem 0"
            }}
        >
            {i + 1}
        </div>
        ),
    dotsClass: "slick-dots",
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
          breakpoint: 970,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
export default settings;