import axios from 'axios';
import config from './../../config';

export const request = async () => {
    try {
        const response = await axios.get(`${config.baseUrl}wp-json/wp/v2/posts/149?_fields=acf&acf_format=standard`);
        return response.data.acf.adicionar_imagens;
    } catch (error) {
        throw new Error('Erro ao buscar dados da galeria:', error);
    }
};
