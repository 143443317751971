import './../../dist/App.min.css';
import './dist/Home.module.min.css';

import { ImageWithHoverZoom } from '../../App.js';
import { request } from '../menu/ApiMenu.js';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import config from '../../config.js';

function Home() {
    const [sections, setSections] = useState([]);
    const [acfData, setAcfData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const menuData = await request();
            setSections(menuData);
        };

        // ! Buscando dados ACF pela API WP Rest da Página Home
        const fetchACFData = async () => {
            try {
                const response = await axios.get(`${config.baseUrl}wp-json/wp/v2/posts/74?_fields=acf&acf_format=standard`);
                const acfData = response.data.acf;
                setAcfData(acfData);
            } catch (error) {
                console.error('Erro ao buscar dados do campo personalizado:', error);
            }
        };

        fetchData();
        fetchACFData();
    }, []);

    var settings = {
        
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            <section id="intro-banner" className="intro-banner" style={{ backgroundImage: `url(${acfData.header_1?.imagem_fundo})` }}>
                <div className="info-banner" >
                    <div className="container">
                        <div className="info-banner-wrapper">
                            <div className="welcome">
                                <h6 className="sect-desc">{acfData.header_1?.descricao_secao}</h6>
                                <div className="title-box">
                                    <h1 className="font-title">{acfData.header_1?.titulo}</h1>
                                </div>
                                <hr />
                                <p className="text">{acfData.header_1?.texto}</p>
                            </div>
                            <div className="open-hour flex-row">
                                <div className="flex-row" style={{width: '100%'}}>
                                    <p>{acfData.header_1?.grupo_horario?.horario_0}</p>
                                    <h2 className="font-title">{acfData.header_1?.grupo_horario?.horario_1}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="few-about">
                <div className="few-about-wrapper container">
                    <div className="flex-row">
                        <div className="left-side">
                            <h6 className="sect-desc">{acfData.sobre?.esquerdo_sobre?.descricao_secao}</h6>
                            <div className="title-box">
                                <h1 className="font-title">{acfData.sobre?.esquerdo_sobre?.titulo}</h1>
                            </div>
                            <hr />
                            <p className="text">{acfData.sobre?.esquerdo_sobre?.texto}</p>
                            <hr />
                            <ul>
                                <li><i className="fa-solid fa-check padding"></i> {acfData.sobre?.esquerdo_sobre?.lista_qualidades?.item_0}</li>
                                <hr className="line" />
                                <li><i className="fa-solid fa-check padding"></i>{acfData.sobre?.esquerdo_sobre?.lista_qualidades?.item_1}</li>
                            </ul>
                            <hr />
                            <a href={acfData.sobre?.esquerdo_sobre?.botao?.url} className="button">{acfData.sobre?.esquerdo_sobre?.botao?.titulo}</a>
                        </div>
                        <div className="right-side flex-row">
                            <div className="flex-column">
                                <div className="quick-fact">
                                    <span>{acfData.sobre?.direito_sobre?.dados?.grupo_dados_0?.dado_0}</span>
                                    <p>{acfData.sobre?.direito_sobre?.dados?.grupo_dados_0?.dado_1}</p>
                                </div>
                                <div className="quick-fact">
                                    <span>{acfData.sobre?.direito_sobre?.dados?.grupo_dados_1?.dado_0}</span>
                                    <p>{acfData.sobre?.direito_sobre?.dados?.grupo_dados_1?.dado_1}</p>
                                </div>
                                <div className="quick-fact">
                                    <span>{acfData.sobre?.direito_sobre?.dados?.grupo_dados_2?.dado_0}</span>
                                    <p>{acfData.sobre?.direito_sobre?.dados?.grupo_dados_2?.dado_1}</p>
                                </div>
                            </div>
                            <div className="image-item">
                                {/* <img src={acfData.sobre?.direito_sobre?.imagem} alt="Imagem sobre a panificadora" /> */}
                                <ImageWithHoverZoom imageUrl={acfData.sobre?.direito_sobre?.imagem} className="image-about" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="menu ">
                <div className="column">
                    <h6 className="sect-desc">{acfData.menu?.descricao_secao}</h6>
                </div>
                <div className="menu-wrapper flex-row container">
                    {sections && sections.filter(section => section.categoria === 'docesBalcao').slice(0, 2).map((section, index) => (
                        <div className="item-menu" key={index}>
                            <div className="image-item">
                                <Link to="/cardapio/">
                                    <ImageWithHoverZoom imageUrl={section.imagem_produto} />
                                </Link>
                            </div>
                            <div className="desc-item">
                                <div className="title-box">
                                    <h2 className="font-title">{section.nome_produto}</h2>
                                </div>
                                <hr />
                                <span className="price-item">{section.preco}</span>
                                <hr />
                                <p>{section.descricao}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="text-center">
                    <Link to={acfData.menu?.botao?.url} className="button">{acfData.menu?.botao?.titulo}</Link>
                </div>
            </section>

            <section className="quality">
    <div className="quality-wrapper flex-row container">
        {acfData.qualidades && Object.values(acfData.qualidades).map((quality, index) => (
            <div className="single-quality" key={index}>
                <img src={quality.qualidade_imagem} alt={`Ícone de ${quality.qualidade_titulo}`} />
                <hr />
                <h5>{quality.qualidade_titulo}</h5>
                <p className="text">{quality.texto}</p>
            </div>
        ))}
    </div>
</section>


            <section className="gallery">
                <div className="gallery-wrapper flex-row container">
                    <div className="gallery-single-img" style={{ backgroundImage: `url(${acfData.galeria?.imagem_galeria_0})` }}></div>
                    <div className="gallery-single-cta flex-row">
                        <div className="gallery-single-cta-wrapper flex-column">
                            <div className="title-box">
                                <h2 className="font-title">{acfData.galeria?.chamada_galeria?.titulo}</h2>
                            </div>
                            <p className="text">{acfData.galeria?.chamada_galeria?.texto}</p>
                            <Link to={acfData.galeria?.chamada_galeria?.botao?.url} className="button">{acfData.galeria?.chamada_galeria?.botao?.title}</Link>
                        </div>
                    </div>
                    <div className="gallery-single-img" style={{ backgroundImage: `url(${acfData.galeria?.imagem_galeria_1})` }}></div>
                </div>
            </section>

            <section className="testimonials">
                <div className="testimonials-wrapper container">
                    <h2 className="sect-desc">{acfData.depoimentos?.descricao_secao}</h2>
                    <Slider {...settings}>
                        {acfData.depoimentos?.card_depoimento?.map((depoimento, index) => (
                            <div className="testimonials-single" key={index}>
                                <div className="testimonial-single-wrapper">
                                    <div className="photo-perfil">
                                        <img src={depoimento.informacoes_depoimento.imagem_autor} className="perfil-image" alt={`Depoimento de ${depoimento.informacoes_depoimento.nome_do_autor}`} />
                                    </div>
                                    <div className="bq-wrapper">
                                        <blockquote className="text">
                                            <i>{depoimento.informacoes_depoimento.texto_depoimento}</i>
                                        </blockquote>
                                    </div>
                                    <div className="author">
                                        <h5>{depoimento.informacoes_depoimento.nome_do_autor}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    );
}

export default Home;