import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import { request } from './../ApiMenu.js';
import './../dist/Menu.module.min.css';
import settings from '../settingsSlider.js';

function BoloGourmet() {
    const [sections, setSections] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const menuData = await request(); // Chamando a função request para obter os dados
            setSections(menuData);
        };

        fetchData();
    }, []);

    return (
        <Slider {...settings}>
            {sections && sections.map((section, index) => (
                // Verifica se a categoria é 'boloEpeciais' antes de renderizar o item
                section.categoria === 'boloGourmet' && (
                    <div className="card-slider" key={index}>
                        <div className="card-wrapper">
                            <div className="img-item" style={{backgroundImage: `url(${section.imagem_produto})`}}></div>
                            <div className="card-text-wrapper">
                                <h2 className="font-title">{section.nome_produto}</h2>
                                <p>{section.descricao} <br /> <b>{section.preco}</b></p>
                            </div>
                        </div>
                    </div>
                )
            ))}
        </Slider>
    );
}

export default BoloGourmet;
