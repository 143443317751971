import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { request } from './ApiContact'; // Importe a função request da ApiContact
import './dist/Contact.module.min.css';

function Contact() {
    const [campos, setCampos] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        descricao_da_secao: '',
        titulo: '',
        input: {
            input_nome: '',
            input_email: '',
            input_assunto: '',
            area_texto: ''
        }
    });
    const [qualidades, setQualidades] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await request(); // Buscar dados da API
                setQualidades(Object.values(data.qualidades)); // Armazenar os campos ACF em state
                setCampos(prevState => ({
                    ...prevState,
                    descricao_da_secao: data.descricao_da_secao,
                    titulo: data.titulo,
                    input: {
                        input_nome: data.input.input_nome,
                        input_email: data.input.input_email,
                        input_assunto: data.input.input_assunto,
                        area_texto: data.input.area_texto
                    }
                }));
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        }
        fetchData();
    }, []);

    function handleInputChange(event) {
        const { name, value } = event.target;
        setCampos({ ...campos, [name]: value });
    }

    async function sendEmail(formData) {
        try {
            await axios.post('http://13.59.65.162/send', formData);
            alert('E-mail enviado com sucesso!'); // Exibir alerta de sucesso
        } catch (error) {
            console.error('Erro ao enviar e-mail:', error);
            alert('Erro ao enviar e-mail. Por favor, tente novamente mais tarde.'); // Exibir alerta de erro
        }
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        console.log('Enviando e-mail:', campos);
        sendEmail(campos);
    }

    return (
        <>
            <section className="quality_contact">
                <div className="container">
                    <div className="quality-wrapper flex-row">
                        {qualidades.map((qualidade, index) => (
                            <div className="single-quality" key={index}>
                                <img src={qualidade.qualidade_imagem} alt={`Icon ${index}`} />
                                <h5>{qualidade.qualidade_titulo}</h5>
                                <p className="text">{qualidade.texto}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="contact-form text-center">
                <div className="container">
                    <div className="contact-wrapper">
                        <h6 className="uppercasetext sect-desc_contact">{campos.descricao_da_secao}</h6>
                        <div className="title-box_contact">
                            <h2 className="font-title font-white">{campos.titulo}</h2>
                        </div>
                        <form onSubmit={handleFormSubmit}>
                            <div className="flex-row">
                                <div className="flex-column 100%">
                                    <div className="flex-row">
                                        <input name="name" id="name" placeholder={campos.input.input_nome} type="text" value={campos.name} onChange={handleInputChange} />
                                        <input name="email" id="email" placeholder={campos.input.input_email} type="email" value={campos.email} onChange={handleInputChange} />
                                    </div>
                                    <input name="subject" placeholder={campos.input.input_assunto} type="text" value={campos.subject} onChange={handleInputChange} />
                                    <textarea name="message" id="message" placeholder={campos.input.area_texto} value={campos.message} onChange={handleInputChange}></textarea>
                                    <hr />
                                    <input className="button_contact" type="submit" value="Enviar a mensagem" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;
