import axios from 'axios';
import config from './../../config';

export const request = async () => {
    try {
        const response = await axios.get(`${config.baseUrl}wp-json/wp/v2/posts/187?_fields=acf&acf_format=standard`);
        return response.data.acf.informacoes_produto;
    } catch (error) {
        throw new Error('Erro ao buscar dados do cardápio:', error);
    }
};
