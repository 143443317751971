import './dist/Header.module.min.css';
import React, { useEffect, useState } from 'react';
import { request } from './ApiHeader';

function Header( {title}  ) {

    const [sections, setSections] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await request(); // Chama a função request para obter os dados
                if (responseData) {
                    setSections(responseData); // Define os dados do cabeçalho
                } else {
                    console.error("Dados do cabeçalho não encontrados.");
                }
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };
            fetchData();
    }, [])
    return (
        <>
            <section id="intro-banner" className="intro-banner_public">
                <div className="container">
                    <div className="left">
                        <div className="title-box">
                            
                            <h1 className="font-title">{title}</h1>
                        </div> {/* title-box */}
                    </div>

                    {sections && (
                        <div className="info-banner">
                            {/* <div className="container"> */}
                                <div className="info-banner-wrapper">
                                    <div className="open-hour flex-row">
                                        <div className="flex-column">
                                            <p>{sections.informacao_0}</p>
                                            <hr className="line" />
                                            <h2 className="font-title">{sections.informacao_1}</h2>
                                        </div>
                                    </div> {/* open-hour */}
                                </div> {/* info-banner-wrapper */}
                            {/* </div> */}
                        </div> 
                    )}
                </div> {/* container */}
            </section> {/* intro-banner */}
        </>
    );
}

export default Header;