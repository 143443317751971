import axios from 'axios';
import config from './../../config.js';


export const request = async () => {
    try {
        const response = await axios.get(`${config.baseUrl}wp-json/wp/v2/posts/79?_fields=acf&acf_format=standard`);
        return response.data.acf; // Retorne toda a resposta, não apenas acf.cabecalho_de_navegacao
    } catch (error) {
        console.error('Erro ao buscar dados do campo personalizado:', error);
        return null; // Retorne null em caso de erro
    }
};
