import React, { useState, useEffect } from 'react';
import './dist/Footer.module.min.css';
import { request } from './ApiFooter.js';

function Footer() {
    const [footerData, setFooterData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const menuData = await request(); // Chamando a função de requisição para obter os dados
                setFooterData(menuData); // Atualizando o estado com os dados recebidos
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <link
                rel="stylesheet"
                href="https://site-assets.fontawesome.com/releases/v6.5.1/css/all.css"
            />
            <footer className="wrapper">
                <div className="container widgets-wrapper text-center">
                    <div className="flex-row">
                        {Object.keys(footerData).map((key, index) => (
                            <div key={index} className="one-third">
                                <aside>
                                    <div className={`textwidget ${index === 1 ? 'second-column' : ''}`}>
                                        <h6 className={`uppercasetext sect-desc ${index === 1 ? 'black-font' : ''}`}>{footerData[key].informacao_0}</h6>
                                        <hr />
                                        <span>{footerData[key].informacao_1}</span>
                                    </div>
                                </aside>
                                {/* Renderizar os links de mídia social na segunda parte da segunda coluna */}
                                {index === 1 && (
                                    <aside>
                                        <div className="textwidget">
                                            {footerData[key].links && Object.values(footerData[key].links).map((link, i) => (
                                                <a key={i} href={link} target="_blank" rel="noopener noreferrer">
                                                    <i className={`fa-brands fa-${link.includes('instagram') ? 'instagram' : link.includes('facebook') ? 'facebook' : 'whatsapp'}`}></i>
                                                </a>
                                            ))}
                                        </div>
                                    </aside>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <hr className="line" />
                <div className="footer-copy text-center">
                    <div className="container">
                        <div className="copyright">
                            &copy; 2024 Panificadora Real | Todos os Direitos Reservados | Desenvolvido por <a href="" target="_blank">*DESENVOLVEDOR</a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;