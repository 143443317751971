import React, { useState } from 'react';
import Slider from "react-slick";
import './dist/Menu.module.min.css';

import DocesEncomenda from './category/DocesEncomenda.js';
import SalgadosEncomenda from './category/SalgadosEncomenda.js';
import BoloFruta from './category/BoloFruta.js';
import BoloChocolate from './category/BoloChocolate.js';
import BoloEspeciais from './category/BoloEspeciais.js';
import BoloGourmet from './category/BoloGourmet.js';
import AdicionaisSalgados from './category/AdicionaisSalgados.js';
import AdicionaisDoces from './category/AdicionaisDoces.js';
import AdicionaisBolo from './category/AdicionaisBolo.js';
import TortasPudim from './category/TortasPudim.js';
import SalgadosBalcao from './category/SalgadosBalcao.js';
import DocesBalcao from './category/DocesBalcao.js';

function Menu() {
  return (
    <div className="container big-row menu-wrapper">
      <MySlider />
    </div>
  );
}

function MySlider() {
  const [category, setCategory] = useState(0);
  const categories = ['Salgados', 'Bolos', 'Doces', 'Tortas e Pudim', 'Adicionais' ];
  const cakes = ['Fruta', 'Chocolate', 'Especiais', 'Gourmet'];
  const adicionais = ['Salgados', 'Doces', 'Bolos'];
  const balcao = ['Doces', 'Salgados'];

  const settings = {
    appendDots: dots => (
      <div
        className="index"
        style={{
          position: "invert-layer",
          top: "-1.5rem",
          width: "100%",
          padding: "0.5vw 0",
          position: "static"
        }}
      >
        <ul className="ul-list-slider index" style={{ paddingTop: "0", justifyContent: "center", gap: "0 2rem", position: "absolute", top: "0", width: "100%",  }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          color: "#777",
        }}
      >
        {categories[i]}
      </div>
    ),
    dotsClass: "slick-dots",
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setCategory(next);
    },
    swipe: false
  };

  const settingsCake = {
    customPaging: i => (
      <div>
        {cakes[i]}
      </div>
    ),
  };

  const settingsBalcao = {
    customPaging: i => (
      <div>
        {balcao[i]}
      </div>
    ),
  };

  const settingsAdd = {
    customPaging: i => (
      <div>
        {adicionais[i]}
      </div>
    ),
  };

  return (
    <>
      <div className="title-box title-menu">
        <h2 className="font-title">Encomende Já!</h2>
      </div>
      
      <section className="slider-container">
      <div className="">
        <Slider {...settings} className='padding-slider-top'>
          
          {/* Slides para a categoria 'Salgados' */}
          <div className="card-slider">
                <SalgadosEncomenda />
          </div>

          <div className='card-slider'>
            {/* SLIDER PARA OS BOLOS */}
              <Slider {...{...settings, ...settingsCake}} >
                {/* Slides para a categoria 'bolos de fruta' */}
                <div className="card-slider">
                  <BoloFruta />
                </div>

                {/* Slides para a categoria 'bolos de chocolate' */}
                <div className="card-slider">
                  <BoloChocolate />
                </div>

                {/* Slides para a categoria 'bolos especiais' */}
                <div className="card-slider">
                  <BoloEspeciais />
                </div>

                {/* Slides para a categoria 'bolos gourmet' */}
                <div className="card-slider">
                  <BoloGourmet />
                </div>
              </Slider>
          </div>

          {/* Slides para a categoria 'Doces' */}
          <div className="card-slider">
            <DocesEncomenda />
          </div>

          <div className='card-slider'>
            <TortasPudim />
          </div>

          <div className='card-slider'>
            {/* Adicionais */}
            <Slider {...{...settings, ...settingsAdd}}>
              <div className='card-slider'>
                <AdicionaisSalgados />
              </div>

              <div className='card-slider'>
                <AdicionaisDoces />
              </div>

              <div className='card-slider'>
                <AdicionaisBolo />
              </div>
              
            </Slider>
          </div>
        </Slider>
      </div>
    </section>


    {/* Item balcão */}
      <section className="slider-container">
      <div className="title-box title-menu">
        <h2 className="font-title">Produtos do balcão</h2>
      </div>
      <div className="">
        <Slider {...{...settings, ...settingsBalcao}}>
          {/* Slides para a categoria 'Doces' */}
          <div className="card-slider">
            <DocesBalcao />
          </div>

          {/* Slides para a categoria 'Salgados' */}
          <div className="card-slider">
                <SalgadosBalcao />
          </div>
        </Slider>
      </div>
    </section>
    </>
    
  );
}

export default Menu;
