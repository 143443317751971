import React, { useState, useEffect } from 'react';
import { ImageWithHoverZoom } from '../../App';
import { request } from './RequestGallery.js'; // Importando a função de requisição

import './dist/Gallery.module.min.css';

function Gallery() {
    const [sections, setSections] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryData = await request(); // Chamando a função de requisição
                setSections(galleryData);
            } catch (error) {
                console.error('Erro ao buscar dados da galeria:', error);
            }
        };

        fetchData();
    }, []);

    const renderMainContent = (section, index) => {
        const fotoTexto = section.secao.foto_texto;
        const imagensGaleria = section.secao.imagens_galeria;

        return (
            <div className={`flex-row image-and-desc ${index % 2 === 0 ? '' : 'row-reverse'}`}>
                <div className="image-gallery">
                    <ImageWithHoverZoom key={index} imageUrl={fotoTexto.imagem_single} />
                </div>
                <div className="desc-image">
                    <h6 className="sect-desc">{fotoTexto.titulo_1}</h6>
                    <div className="title-box">
                        <h2 className="font-title">{fotoTexto.titulo_0}</h2>
                    </div>
                    <p className='text'>{fotoTexto.texto}</p>
                </div>
            </div>
        );
    };

    const renderSections = () => {
        return sections.map((section, index) => (
            <>
                <section key={index} className={`gallery-products ${index % 2 === 0 ? 'gallery-top' : 'gallery-middle'}`}>
                    <div className="container">
                        <div className="flex-row">
                            <div className={`flex-column ${index % 2 === 0 ? 'flex-end' : 'flex-start'}`}>
                                {renderMainContent(section, index)}
                                <div className="box-images flex-row">
                                    {section.secao.imagens_galeria.map((image, i) => (
                                        <div className='image-gallery'>
                                            <ImageWithHoverZoom key={i} imageUrl={image} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr className='line-orange' />
            </>
        ));
    };

    return (
        <>
            {renderSections()}
        </>
    );
}

export default Gallery;